(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("konva"), require("jszip"), require("magic-wand-tool"));
	else if(typeof define === 'function' && define.amd)
		define(["konva", "jszip", "konmagic-wand-tool"], factory);
	else if(typeof exports === 'object')
		exports["dwv"] = factory(require("konva"), require("jszip"), require("magic-wand-tool"));
	else
		root["dwv"] = factory(root["Konva"], root["JSZip"], root["MagicWand"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__436__, __WEBPACK_EXTERNAL_MODULE__626__, __WEBPACK_EXTERNAL_MODULE__812__) {
return 